<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Nieuw item" />

    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">General information</h3>
            <p class="mt-1 text-sm text-gray-600">
              The button text and link can be left empty when no button should be shown.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          
          <form @submit.prevent="handleSave" method="POST">
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-6">
                    <label for="title" class="block text-sm font-medium text-gray-700">Big title</label>
                    <input type="text" name="title" id="title" autocomplete="title" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" required v-model="homepage_item.title">
                  </div>

                  <div class="col-span-6 sm:col-span-6">
                    <label for="subtitle" class="block text-sm font-medium text-gray-700">Smaller subtitle</label>
                    <input type="text" name="title" id="subtitle" autocomplete="subtitle" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" required v-model="homepage_item.subtitle">
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="last_name" class="block text-sm font-medium text-gray-700">Title button</label>
                    <input type="text" name="last_name" id="last_name" autocomplete="family-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="homepage_item.btn_txt">
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="company_website" class="block text-sm font-medium text-gray-700">
                      URL button
                    </label>
                    <div class="mt-1 flex rounded-md shadow-sm">
                      <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                        http://
                      </span>
                      <input type="text" name="company_website" id="company_website" class="focus:ring-orange-500 focus:border-orange-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300" placeholder="www.example.com" v-model="homepage_item.btn_link">
                    </div>
                  </div>
                  
                </div>

              </div>
              
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" @click="saveItem">
                  Save
                </button>
              </div>
            </div>
          </form>
          
        </div>
      </div>
    </div>

  </main>
</template>

<script>
import HompageItemService from '../../../services/admin/homepage_items/service';

export default {
  name: 'HomepageItem',
  data() {
    return {
      homepage_item: {
        id: null,
        title: "",
        subtitle: "",
        btn_txt: "",
        btn_link: ""
      },
      submitted: false
    }
  },
  methods: {
    saveItem() {

      var data = {
        title: this.homepage_item.title,
        subtitle: this.homepage_item.subtitle,
        btn_txt: this.homepage_item.btn_txt,
        btn_link: this.homepage_item.btn_link
      };

      HompageItemService.create(data)
        .then(response => {
          this.homepage_item.id = response.data.id;
          console.log(response.data);
          this.submitted = true;
          this.$router.push(`/homepageitems/edit/${this.homepage_item.id}`);
        })
        .catch(e => {
          console.log(e);
        });
    },
    newItem() {
      this.submitted = false;
      this.homepage_item = {};
    }
  }
}
</script>